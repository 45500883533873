export const documentResolver = (doc: any) => {
  // Route for blog posts
  if (doc.type === "post") {
    return `/${doc.uid}/`
  }
  // Homepage route fallback
  return "/"
}

export const pageResolver = (page: number) => {
  return page === 1 ? "/" : `/page/${page}/`
}
