module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"jeroendruwe","previews":true,"path":"/preview","pages":[{"type":"Post","match":"/:uid/","path":"/blog-preview","component":"/opt/build/repo/src/templates/PostTemplate.tsx"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"jeroendruwe.be","short_name":"jeroendruwe","start_url":"/","background_color":"#4fd1c5","theme_color":"#4fd1c5","display":"standalone","icon":"src/images/site-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-113623965-1","anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
